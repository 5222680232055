import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Spoiler } from "../../components/Spoiler";
import { Callout } from "../../components/Callout";
import { Footer, Source, SourceRef } from "../../components/Footer";
export const _frontmatter = {
  "title": "Typeclasses in Typescript",
  "subtitle": "A guide to using and building typeclasses in Typescript with fp-ts",
  "date": "2020-02-11T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "typescript", "typeclasses", "monad", "functional programming", "fp-ts"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`In statically typed functional programming, `}<em parentName="p">{`typeclasses`}</em>{` are often used in place of interfaces to achieve polymorphism (that is, writing code that works for multiple different types of values). `}<a parentName="p" {...{
        "href": "https://github.com/fantasyland/fantasy-land"
      }}>{`Fantasy-land`}</a>{` is a JS standard for algebraic structures, which mostly employs `}<em parentName="p">{`interfaces`}</em>{` to achieve reuse. `}<a parentName="p" {...{
        "href": "https://github.com/gcanti/fp-ts"
      }}>{`Fp-ts`}</a>{` is a popular library that contains the same algebraic structures from fantasy-land, except it's built on `}<em parentName="p">{`typeclasses`}</em>{`. Typeclasses are much more extensible and lend themselves to certain type structures that would otherwise be complex to model with interfaces, at the cost of being a bit more verbose & clunky at times.`}</p>
    <p>{`For example, let's say we wanted to build a function that takes a value, turns it into a string, and logs it. Let's suppose we can't just call `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JSON.stringify`}</code>{`, and we need each object to be able to specify its own logic to achieve stringification. `}</p>
    <p>{`Using an interface, this would look roughly like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Printable`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`logValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Printable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`console`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`This works fine, but comes with a few limitations. First, it can only be used with values from modules that depend on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Printable`}</code>{` interface. For instance, in order to use it with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`string`}</code>{` value, you'd need to wrap it in an adapter object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Paul"`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`logValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StringPrintable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`This isn't too bad, but suppose we needed the functionality of two interfaces, we'd need a special adapter which implements both of those interfaces. The problem gets worse as we add interfaces.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Paul"`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`logValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StringPrintableAndComparable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`In Typescript, we encode typeclasses with interfaces, but we separate the value from the method it's implementing. Instead of implementing that interface with our original value, we create a separate value and have that second value implement the interface. Whenever we need to write a function for some value that "implements" the typeclass, we just have our function take two values; the original value, and an `}<em parentName="p">{`instance`}</em>{` of a typeclass for that value.`}</p>
    <p>{`For example, let's build the same `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`logValue`}</code>{` function with a typeclass. Our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Printable`}</code>{` interface becomes a typeclass, and it needs to work for `}<em parentName="p">{`any`}</em>{` value, so we introduce a type parameter, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` (The type is irrelevant to the definition of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Printable`}</code>{`, so we keep it concise). Our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`print`}</code>{` function turns from a no-arg function, into a function that takes the value, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`, and returns a string.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Printable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`logValue`}</code>{` changes to take two values; the original value that we're converting, and an instance of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Printable`}</code>{` typeclass for the original value (which will hold the logic for converting). We can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`print`}</code>{` method on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Printable`}</code>{` instance, passing our original value in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` logValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` stringable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Printable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`console`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`stringable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Here, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`logValue`}</code>{` works for `}<em parentName="p">{`any`}</em>{` value. We don't need to wrap the value in an adapter layer, and adding capabilities simply consists of adding typeclass parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`declare`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` logValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` stringable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Printable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` equal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Equal`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Let's take a closer look at that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal`}</code>{` typeclass. In plain Javascript, it's sometimes useful to compare two values. Oftentimes libraries take the shortcut of using the `}<a parentName="p" {...{
        "href": "https://www.ecma-international.org/ecma-262/5.1/#sec-11.9.6"
      }}>{`strict equality comparison algorithm`}</a>{` in times that it needs to determine if two values are equal. For example, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Set`}</code>{`s in Javascript determine equality using this algorithm. By default, a React `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PureComponent`}</code>{` will compare props based on strict equality. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reselect`}</code>{` library also uses strict equality to determine if parameters are the same. Sometimes this is fine, but other times it's useful to have finer-grained control over how two values are determined to be "equal."`}</p>
    <p>{`If we were to build this as an interface, it might look something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Equal`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`other`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Equal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`and then a few implementations:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`User`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`implements`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Equal`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`private`}</span>{` id`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Point`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`implements`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Equal`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`private`}</span>{` x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`private`}</span>{` y`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`point`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Point`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`y `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`y 
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`However this isn't totally accurate, For example, I can have two different instances of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal`}</code>{`s, but I can still compare them, while satisfying the typechecker:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Equal `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Equal `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Point`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`30`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// type-checks, but is logically unsound`}</span></code></pre>{`
      `}</div>
    <p>{`To make this more type-safe, we can use a typeclass to model the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal`}</code>{` interface. Instead of building a method that takes a single parameter, we build a method that takes two parameters; the original value to operate on, and an additional parameter to compare it to:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Equal`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`left`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Since the two parameters are fixed on a single type, there's no chance to mess this up. Using this new typeclass, let's build a function that takes in an array of values and removes the duplicates. You may be tempted to just create a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Set`}</code>{` from the array, but `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Set`}</code>{` uses strict equality checking, which may lead to surprising results:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` users `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Bob"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Susan"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Bob"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`console`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Set`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`This logs:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Set {
  { id: 1, name: 'Bob' },
  { id: 2, name: 'Susan' },
  { id: 1, name: 'Bob' } }`}</code></pre>{`
      `}</div>
    <p>{`Oops! We wanted to remove duplicates, but `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Set`}</code>{` can only make decisions based on referential equality.`}</p>
    <p>{`Let's write a function using our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal`}</code>{` typeclass which implements this functionality correctly:`}</p>
    <p>{`In order to use this with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`User`}</code>{` values, we simply need to construct an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal<User>`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/** Two users are equal if their ids are equal */`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` userEq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Equal`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`User`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`l`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` r`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` l`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`And then use it when invoking `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`removeDupes`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` users `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Bob"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Susan"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Bob"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`console`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`removeDupes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` userEq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`This logs:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`[ { id: 1, name: 'Bob' }, { id: 2, name: 'Susan' } ]`}</code></pre>{`
      `}</div>
    <p>{`which is much closer to what we'd expect.`}</p>
    <h3>{`Thinking in typeclasses`}</h3>
    <p>{`Using typeclasses brings a slight shift of thinking when building polymorphic functions. Instead of restricting parameters to all subclasses of an interface, you just introduce a type parameter, and then also add a typeclass for the needed functionality, for example:`}</p>
    <p>{`Instead of working for only subclasses of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Talker`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`speak`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`talker`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Talker`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`typeclasses allow any values that have an instance of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Talker`}</code>{` typeclass:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` speak`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`talker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Talker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`When using libraries that are built on typeclasses, instead of thinking, "Does this value implement the X interface," it's more useful to think "Is there an instance of typeclass X for this value?"`}</p>
    <h2>{`Creating typeclass instances`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal`}</code>{` typeclass we just wrote exists in fp-ts as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{`. Fp-ts also includes the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`removeDupes`}</code>{` function we wrote, named as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`uniq`}</code>{` (in its `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Array`}</code>{` module). Let's get a feel for using the fp-ts library by using the fp-ts equivalents of the functions we built.`}</p>
    <p>{`Creating an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` is as simple as building a value that "implements" the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` interface. Let's suppose we had a type that models coordinates on a 2d plane as tuples (an X value and a Y value), and we had a list of them:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Point `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre>{`
      `}</div>
    <p>{`Let's say we wanted to remove each duplicate value before processing them further. We'll accomplish this in two steps: `}</p>
    <ol>
      <li parentName="ol">{`Build an instance of the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Eq`}</code>{` typeclass for `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Point`}</code>{`s.`}</li>
      <li parentName="ol">{`Use that `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Eq`}</code>{` instance with the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`uniq`}</code>{` function.`}</li>
    </ol>
    <p>{`All we need to do make the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` instance is to build a value whose type is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq<Point>`}</code>{`. There's just one method we have to implement, and that's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`equal(a: Point, b: Point) => boolean`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Eq `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Eq'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` pointEq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Eq`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Point`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Point`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Point`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ???`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`what should the implementation of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`equals`}</code>{` look like?`}</p>
    <Spoiler label="Click to see equals" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` pointEq`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Eq`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`Point`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`equals`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` Point`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` b`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` Point`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`===`}</span>{` b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`&&`}</span>{` a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`===`}</span>{` b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`uniq`}</code>{` function (unlike our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`removeDupes`}</code>{` function) is curried, and takes the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` instance first:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` uniq`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`E`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Eq`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`Array`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre>{`
      `}</div>
    <p>{`It then returns a function that takes an array of those values, and returns a filtered version of that array, where equality between elements is determined by the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` instance.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` uniq `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Array'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` filterPoints `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`uniq`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pointEq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// returns a function that will filter an array`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`filterPoints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// returns a filtered list of points`}</span></code></pre>{`
      `}</div>
    <h3>{`Composing typeclasses`}</h3>
    <p>{`Constantly implementing typeclasses for straightforward values like combinations of numbers can quickly become cumbersome. Fp-ts provides useful utilities for building complex typeclasses by composing smaller ones. For instance, there is already an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` instance which compares numbers, and there is already a function which builds a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` instance for tuples that contain any value. Let's use these utilities to compose an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Eq`}</code>{` instance for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Point`}</code>{`s, rather than handcrafting our own.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Eq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` getTupleEq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` eqNumber `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Eq'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` pointEq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Eq`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Point`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getTupleEq`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eqNumber`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` eqNumber`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <Callout mdxType="Callout">
Two tuples are equal only if their contents are equal, so you can't have one typeclass instance for all tuples (since tuples have many different types of values!), `getTupleEq` "solves" this by taking any number of tuple instances for the values inside and constructing one on the fly.
    </Callout>
    <h2>{`The Monoid typeclass`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monoid`}</code>{` typeclass from fp-ts abstracts over how to `}<em parentName="p">{`combine`}</em>{` two values.`}</p>
    <p>{`It contains just two members:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Monoid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  empty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`concat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`concat`}</code>{` encapsulates the logic to combine the values, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`empty`}</code>{` is a value of the same type that represents a 'zero' value. We can make an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monoid<number>`}</code>{` to represent combining two numbers with addition:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` addition`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Monoid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  empty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`concat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

addition`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`concat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 12`}</span></code></pre>{`
      `}</div>
    <p>{`What can we do with this now? Let's take a look at a useful method from fp-ts, foldMap.`}</p>
    <p>{`Let's introduce this method with a thought experiment. Let's suppose you have a list of `}<em parentName="p">{`apples`}</em>{` and you have way to `}<em parentName="p">{`combine two apples`}</em>{` into one apple. Given these two things, you should be able to take that list and "squash" it into one, taking each apple and combining it with the previous one.`}</p>
    <p>{`Let's also suppose that you have a list of "bananas" and a way to combine two oranges into one orange. What would you need to combine the list of bananas into a single orange? If you had a way to turn bananas into oranges, you could iterate over the bananas, turning each one into an orange, and then combine them once they're in their orange state.`}</p>
    <p>{`This is the pattern behind `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`foldMap`}</code>{`, except it's `}<em parentName="p">{`generalized`}</em>{`, meaning it works for a list of any kind of contents, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`, and any kind of combination method for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monoid<B>`}</code>{`), as long as we have a way to turn those `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`'s into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`'s.`}</p>
    <p>{`Here's the signature for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`foldMap`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}>{`foldMap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token constant"
            }}>{`M`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Monoid`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`fa`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span></code></pre>{`
      `}</div>
    <p>{`It can be broken up into three different parts, let's take it one section at a time: `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<B>(M: Monoid<B>)`}</code>{`, which returns a: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<A>(f: (a: A) => B)`}</code>{`, which returns a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(fa: A[]) => B`}</code></p>
    <p>{`This function allows us to take an array of values of type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`, a monoid for values of type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`, and a function which turns `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`'s into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`'s, and finally, returns a single `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`. Phew that was a mouthful, let's use this to count all the login counts across some users.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Monoid `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fp-ts/lib/Monoid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` foldMap `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fp-ts/lib/Array"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` User `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` logins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` User`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Paul"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` logins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Sue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` logins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Bob"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` logins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`foldMap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`addition`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`u`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` u`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`logins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 25`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`foldMap`}</code>{` allows us to combine values by transforming them into something our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monoid`}</code>{` can combine. If there are no items in the list, then `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`foldMap`}</code>{` simply takes the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`zero`}</code>{` value from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monoid`}</code>{`.`}</p>
    <p>{`This is similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sumBy`}</code>{` in lodash:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}>{`_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sumBy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`u`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` u`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`logins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`The difference here is that we can use `}<em parentName="p">{`any`}</em>{` Monoid to combine these values, not just the addition Monoid! What if we wanted to compute whether or not all of the users in our list are frequent users? We'd want to reduce the array to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` if all of the users have logged in at least five times, and false if one of them have not.`}</p>
    <p>{`First, we'd want to map each user to a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`boolean`}</code>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` if they have logged in five time, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{` otherwise), then we'd want to combine those `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`boolean`}</code>{` values with a monoid that operates on boolean values.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`declare`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` andMonoid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Monoid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Boolean`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`userIsFrequent`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`u`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` u`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`logins `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`foldMap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`andMonoid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`userIsFrequent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// true`}</span></code></pre>{`
      `}</div>
    <p>{`what does the implementation of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`andMonoid`}</code>{` need to be in order to facilitate this? Try implementing this on your own and test the results with various arrays of users.`}</p>
    <Spoiler label="Click to see andMonoid" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` andMonoid`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Monoid`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`Boolean`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  empty`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`concat`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` b`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` a `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`&&`}</span>{` b
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <p>{`as another exercise, try to implement `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`foldMap`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` foldMap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token constant"
            }}>{`M`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Monoid`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token constant"
            }}>{`M`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`fab`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`as`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// M.concat combines two b's`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// M.empty is the "empty" state of 'b'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fab converts an 'a' into a 'b'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// as is an array of 'a's`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <Spoiler label="Click to see foldMap" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` myFoldMap`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token constant"
              }}>{`M`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` Monoid`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`B`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`as`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`B`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{`
  `}<span parentName="code" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token constant"
              }}>{`M`}</span></span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`fab`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`as`}</span></span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`as`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`reduce`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`b`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` a`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`M`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`concat`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`fab`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`M`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`empty`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span></code></pre>{`
      `}</div>
    </Spoiler>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      